// Here you can add other styles
.UserAvatar--dark {
  color: #FFF
}

.main .container-fluid {
  padding: 20px;
}

.bg-danger-cell {
  color: #FFFFFF;
  background-color: #c34a49;
}

label.required:after {
  content: '*';
  color: red;
  padding-left: 5px;
}

// Navigation icon
.sidebar {
  background-color: #212528;
}

.sidebar .nav-dropdown.open {
  background-color: #212528;
}

.sidebar .nav-dropdown-items {
  background-color: #2F3539;
}

.sidebar .nav-dropdown-items .nav-link {
  padding-left: 30px;
}


.sidebar .nav-link:hover {
  background-color: rgba(0,0,0,0.1)
}

.sidebar .nav-dropdown.open .nav-link.active {
  background-color: #20a8d8;
}

.sidebar .nav-link.active .nav-icon {
  color: #FFFFFF;
}

// Datepicker related css
.datepicker .react-datepicker-wrapper {
  display: block;
}

.datepicker .react-datepicker__input-container {
  display: block;
}

.datepicker .react-datepicker-popper {
  z-index: 999;
}

.form-control:disabled {
  border: unset;
  background-color: hsl(0,0%,99%);
  cursor: text;
}

.form-control:read-only {
  border: unset;
  background-color: hsl(0,0%,99%);
  cursor: text;
}

.form-control:read-only:focus {
  box-shadow: unset;
}

.form-control.is-invalid {
  background: none;
}

